import { fetchAllMunicipalities } from 'actions/subscriptions'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import { useEffect } from 'react'

export function useMunicipalities(countryCode: string) {
    const dispatch = useAppDispatch()
    const { entities: municipalities, result } = useAppSelector(
        (state) => state.subscriptions.municipalities
    )
    useEffect(() => {
        dispatch(fetchAllMunicipalities(countryCode))
    }, [countryCode])

    return { municipalities: municipalities, result }
}
