import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export function useIsTest() {
    return (
        window.location.host.indexOf('l.') === 0 ||
        window.location.host.indexOf('test') === 0 ||
        window.location.host.indexOf('localhost') === 0
    )
}

export function useToggle(initial) {
    const [toggleBool, setToggle] = useState(initial)
    const onToggle = () => setToggle(toggleBool !== true)

    return [toggleBool, onToggle]
}

export function useWindowSize() {
    const isClient = typeof window === 'object'

    function getSize() {
        return {
            width: isClient ? window.innerWidth : undefined,
            height: isClient ? window.innerHeight : undefined,
        }
    }

    const [windowSize, setWindowSize] = useState(getSize)

    useEffect(() => {
        if (!isClient) {
            return false
        }

        function handleResize() {
            setWindowSize(getSize())
        }

        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, []) // Empty array ensures that effect is only run on mount and unmount

    return windowSize
}

export function useForm(extraValidator = false) {
    const [values, setValues] = useState({})
    const [errors, setErrors] = useState({})
    const [validations, setValidations] = useState({})

    const onChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value })
        setErrors({})
    }
    const validate = (showErrors = true) => {
        let isValid = true
        const cloneErrors = { ...errors }
        Object.keys(validations).forEach((key) => {
            const validators = Array.isArray(validations[key])
                ? validations[key]
                : [validations[key]]
            let errorFound = false
            validators.forEach((validator) => {
                if (!errorFound) {
                    const invalid = validator(values[key])
                    if (invalid) {
                        isValid = false
                        errorFound = true
                    }
                    cloneErrors[key] = invalid
                }
            })
            if (!cloneErrors[key] && extraValidator) {
                const invalid = extraValidator(key, values[key])
                if (invalid) {
                    isValid = false
                    cloneErrors[key] = invalid
                }
            }
        })
        showErrors && setErrors(cloneErrors)
        return isValid
    }

    const setValue = (name, value) => setValues({ ...values, [name]: value })
    const isValid = (val = false) => validate(val)

    return {
        values,
        onChange,
        setValues,
        errors,
        setValue,
        validate,
        isValid,
        setValidations,
    }
}

export function useOutsideClick(ref, onOutsideClick) {
    const onClick = (e) => {
        const containerNode = ref && ref.current
        if (containerNode && e.target !== containerNode && !containerNode.contains(e.target)) {
            onOutsideClick(e)
        }
    }
    useEffect(() => {
        window.addEventListener('click', onClick)
        return () => window.removeEventListener('click', onClick)
    }, [])
}

export function useDidMount() {
    const didMountRef = useRef(null)

    useEffect(() => {
        didMountRef.current = true
    }, [])

    return didMountRef.current
}

export function useHistoryProfileTabs() {
    const { updated } = useSelector((state) => state.profileTabs)
    const dispatch = useDispatch()

    return {
        ...historyProfileTabs(dispatch),
        updated,
        doUpdate: () => dispatch({ type: 'UPDATE_TABS' }),
    }
}

export function historyProfileTabs(dispatch) {
    const maxTabs = 9
    const setNewTabs = (_newTabs) => {
        localStorage.setItem('history_profile_tabs', JSON.stringify(_newTabs))
        dispatch({ type: 'UPDATE_TABS', updated: Date.now() })
    }
    const getTabs = (asArray = false) => {
        const data = localStorage.getItem('history_profile_tabs')
        if (data) {
            const parsed = JSON.parse(data)
            return asArray ? Object.keys(parsed).map((key) => parsed[key]) : parsed
        }
        return asArray ? [] : {}
    }
    const setTab = (provider, url) => {
        const newTabs = {
            ...getTabs(),
            [provider.service_provider_id]: {
                provider,
                url,
                added: Date.now(),
            },
        }
        const nrTabs = Object.keys(newTabs).length
        if (nrTabs >= maxTabs) {
            const notPinned = Object.keys(newTabs).filter(
                (key) => !newTabs[key].isPinned && key !== provider.service_provider_id
            )
            const ordered = notPinned.sort(function (a, b) {
                return (
                    new Date(newTabs[a] && newTabs[a].date) -
                    new Date(newTabs[b] && newTabs[b].date)
                )
            })
            const tooMuch = nrTabs - maxTabs
            for (let i = 0; i < tooMuch; i++) {
                delete newTabs[ordered[i]]
            }
        }
        setNewTabs(newTabs)
    }
    const addData = (providerId, extra) => {
        const tabs = getTabs()
        const newTabs = {
            ...tabs,
            [providerId]: {
                ...tabs[providerId],
                ...extra,
            },
        }
        setNewTabs(newTabs)
    }
    const deleteTab = (providerId) => {
        const newTabs = { ...getTabs() }
        delete newTabs[providerId]
        setNewTabs(newTabs)
    }

    return {
        getTabs,
        setTab,
        addData,
        deleteTab,
    }
}

function getQuery(defaultOptions = {}) {
    const url = new URL(window.location)
    const result = defaultOptions
    for (const [key, value] of url.searchParams.entries()) {
        result[key] = value
    }
    return result
}

export function usePrevious(value) {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef()

    // Store current value in ref
    useEffect(() => {
        ref.current = value
    }, [value]) // Only re-run if value changes

    // Return previous value (happens before update in useEffect above)
    return ref.current
}

export function useUrlQuery(defaultOptions = {}) {
    const [query, setQuery] = useState(getQuery(defaultOptions))
    const onSetQuery = (diff = {}) => {
        setQuery({ ...query, ...diff })
        const url = new URL(window.location)
        Object.keys(diff).forEach((key) => {
            url.searchParams.set(key, diff[key])
        })
        window.history.pushState({}, '', url)
    }

    return {
        query,
        onSetQuery,
    }
}

export function useCurrentCountry() {
    return useSelector((state) => state.countries.countries[0])
}

export function useInterval(callback, delay) {
    const savedCallback = useRef()

    // Remember the latest callback
    useEffect(() => {
        savedCallback.current = callback
    }, [callback])

    // Set up the interval
    useEffect(() => {
        const tick = () => {
            savedCallback.current()
        }
        if (delay !== null) {
            let interval = setInterval(tick, delay)
            return () => clearInterval(interval)
        }
    }, [delay])
}
