import { Col, Row } from '@advanza/grid'
import { useIsMobile } from '@advanza/hooks'
import { Divider, Icon } from '@advanza/ui'
import ProfileProjectsPhotos from 'components/profileProjects/ProfileProjectsPhotos'
import SearchLocationInput from 'components/ui/SearchLocationInput'
import { useChangeEntity, validateDetailed } from 'hooks/entityHooks'
import { useCurrentCountry } from 'hooks/miscHooks'
import { useDeleteModal } from 'hooks/modalHooks'
import { useProfileProjectsItem } from 'hooks/profileProjectsHooks'
import React from 'react'
import style from './profileProjects.module.css'

const fields = (yearOptions, optional) => {
    return {
        title: {
            type: 'text',
            placeholder: 'Title',
            legend: true,
            validator: (val) => val && val.length > 2 && val.length <= 70,
            maxLength: 70,
            errorMsg: 'Add a title',
        },
        description: {
            type: 'text',
            textArea: true,
            inputStyle: {
                minHeight: 130,
            },
            placeholder: <span>Description {optional}</span>,
            legend: true,
        },
        place_name: {
            type: 'text',
            placeholder: <span>City {optional}</span>,
            legend: true,
        },
        price: {
            type: 'text',
            placeholder: <span>Price {optional}</span>,
            legend: true,
            validator: (val) => val === null || !val || (val && val.length <= 30),
            maxLength: 30,
        },
        year: {
            type: 'selectSimple',
            options: yearOptions,
            placeholder: 'Year',
            legend: true,
        },
        active: {
            type: 'toggle',
            placeholder: 'Visible on profile',
            legend: true,
            inline: true,
        },
        files: {
            validator: (val, entity) =>
                (val && val.length >= 1) || (!entity.active && val && val.length === 0),
        },
    }
}

const ProfileProjectsEntity = ({ projectId, getValidator }) => {
    const country = useCurrentCountry()
    const isMobile = useIsMobile()
    const optional = <small>(optional)</small>
    const { getYearOptions, isNew, onDelete, isDeleting, disableButtons } =
        useProfileProjectsItem(projectId)
    const { entity, renderInput, onChangeEntity, validate } = useChangeEntity(
        {
            store: 'providers',
            name: 'projects',
            entityId: projectId,
        },
        fields(getYearOptions(), optional)
    )
    const { openModal: openDeleteModal, modal: deleteModal } = useDeleteModal(
        isDeleting,
        () => onDelete(projectId),
        'project'
    )

    getValidator(
        validateDetailed.bind(
            null,
            'project',
            validate,
            fields(getYearOptions(), optional),
            entity,
            projectId
        )
    )

    const onChangeLocation = (postalCode) => {
        onChangeEntity({
            place_name: postalCode.place_name,
        })
    }

    return (
        <>
            <Row middle="xs" className={style.profileProjectsEntity}>
                <Col xs>
                    {renderInput('title')}
                    <Divider m />
                    <ProfileProjectsPhotos
                        projectId={projectId}
                        providerId={entity.service_provider_id}
                        disableButtons={disableButtons}
                        missingPhotoError={entity._errors && entity._errors.files}
                    />
                    <Row>
                        <Col xs={12} sm={4}>
                            <div className={style.searchLocation}>
                                <span>City {optional}</span>
                            </div>
                            <SearchLocationInput
                                name="location"
                                value={entity.place_name}
                                countryCode={country}
                                withIcon={!isMobile}
                                onChangeLocation={onChangeLocation}
                                legend
                                altStyle
                                limit={5}
                            />
                            <Divider m />
                        </Col>
                        <Col xs={12} sm={4}>
                            {renderInput('price')}
                            <Divider m />
                        </Col>
                        <Col xs={12} sm={4}>
                            {renderInput('year')}
                            <Divider m />
                        </Col>
                    </Row>
                    {renderInput('description')}
                    <Divider m />
                    <Row>
                        <Col xs>{renderInput('active')}</Col>
                        {!isNew && (
                            <Col x>
                                <button onClick={openDeleteModal}>
                                    <Icon name="delete_forever" />
                                </button>
                            </Col>
                        )}
                    </Row>
                </Col>
            </Row>
            {deleteModal}
        </>
    )
}

export default ProfileProjectsEntity
