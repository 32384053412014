import { GoogleMap, useJsApiLoader } from '@react-google-maps/api'
import { FC, useEffect, useRef, useState } from 'react'
import {
    countryCenters,
    dataSets,
    defaultZoom,
    GOOGLE_MAPS_API_KEY,
    mapIds,
    mapOptionsStatic,
    polygonStyles,
} from './googleMapConstants'
import style from './MunicipalityMap.module.css'

// Store some vars outside the react function because the event listeners are set once
let lastHover: number | null = null
const listeners: Record<string, google.maps.MapsEventListener> = {}

/**
 * Google maps with municipalities.
 *
 * It uses datasets and map styles in the cloud console, see link
 *
 * The map can be styled and customized in the cloud console.
 *
 * @see https://console.cloud.google.com/google/maps-apis/datasets?project=avid-garage-354207&inv=1&invt=AbllKg
 *
 * */
export const MunicipalitiesMap: FC<MunicipalitiesMapProps> = ({
    height = '100%',
    municipalities,
    countryCode = 'nl', // default to "NL" if not provided
    id = 'default',
    width,
    onClickMunicipality,
    onHoverMunicipality,
    refreshTrigger,
    municipalityStyles,
    center,
    zoom,
    className = '',
}) => {
    const [map, setMap] = useState<google.maps.Map | null>(null)
    const bannerRef = useRef<HTMLDivElement | null>(null)
    const prevMapCenterRef = useRef<{ lat: number; lng: number } | undefined>()

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: GOOGLE_MAPS_API_KEY,
        libraries: ['maps', 'places'],
        mapIds: Object.values(mapIds),
    })

    const getDefaultCenter = () => {
        const centerMunicipality = municipalities[countryCenters[countryCode.toUpperCase()]]
        if (!centerMunicipality) {
            return { lat: 0, lng: 0 }
        }
        return { lat: centerMunicipality.latitude, lng: centerMunicipality.longitude }
    }

    // The styling of the polygon
    const getFeatureStyle = (featureStyleFunctionOptions: any) => {
        const datasetFeature = featureStyleFunctionOptions.feature
        // The dataset attributes you put in your polygon data
        const { municipality_id } = datasetFeature.datasetAttributes
        const isHovered = lastHover === municipality_id

        if (municipalityStyles) {
            const featureStyle =
                municipalityStyles[parseInt(municipality_id)] || municipalityStyles.fallBack || {}
            const baseFillOpacity = featureStyle.fillOpacity ?? polygonStyles.inactive.fillOpacity
            return {
                ...polygonStyles.inactive,
                ...featureStyle,
                fillOpacity: isHovered ? Math.max(baseFillOpacity * 1.2, 0.4) : baseFillOpacity,
            }
        } else {
            return isHovered ? polygonStyles.hover : polygonStyles.inactive
        }
    }

    // Click handler for the map polygons
    const handleClick = (event: any) => {
        const { features } = event
        if (!features || !features.length) return
        const { municipality_id } = features[0].datasetAttributes
        const municipality = municipalities[parseInt(municipality_id)]
        if (onClickMunicipality && municipality) {
            onClickMunicipality(municipality)
        }
    }
    const datasetLayer = map?.getDatasetFeatureLayer(dataSets[countryCode])
    // Hover handler for the map polygons
    const handleMouseMove = (event: any) => {
        const { features } = event
        if (!features || !features.length) return
        const { municipality_id } = features[0].datasetAttributes
        lastHover = municipality_id
        if (onHoverMunicipality) {
            const municipality = municipalities[parseInt(municipality_id)]
            if (municipality) {
                const { banner } = onHoverMunicipality(municipality)
                if (banner && bannerRef.current) {
                    bannerRef.current.innerHTML = banner
                }
            }
        }
        if (datasetLayer) {
            // Reapply styling so the hover effect is immediate
            datasetLayer.style = getFeatureStyle
        }
    }

    // Initialize / refresh map layers and event listeners
    useEffect(() => {
        if (map) {
            // Set the correct map ID for the country
            // @ts-ignore
            map.setMapId(mapIds[countryCode])

            // Configure dataset with municipality polygons
            const datasetLayer = map.getDatasetFeatureLayer(dataSets[countryCode])
            datasetLayer.style = getFeatureStyle

            // Overwrite the listeners so the getStyle fn that they call is up to date.
            if (listeners[`click_${id}`]) {
                listeners[`click_${id}`].remove()
            }
            if (listeners[`mouse_${id}`]) {
                listeners[`mouse_${id}`].remove()
            }
            listeners[`click_${id}`] = datasetLayer.addListener('click', handleClick)
            listeners[`mouse_${id}`] = datasetLayer.addListener('mousemove', handleMouseMove)

            // Position map after first load, use provided center or the country default
            const hasCenter = center || getDefaultCenter().lat > 0
            if (!map.getZoom() && hasCenter) {
                map.setZoom(zoom || defaultZoom[countryCode])
                map.setCenter(center || getDefaultCenter())
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, municipalities, refreshTrigger])

    // Update the center based on center prop
    useEffect(() => {
        const hasNewCenter =
            center?.lat !== prevMapCenterRef.current?.lat ||
            center?.lng !== prevMapCenterRef.current?.lng

        if (map && hasNewCenter) {
            map.panTo(center || getDefaultCenter())
        }
        prevMapCenterRef.current = center
    }, [center, map])

    return (
        <div className={`${style.map} ${className}`}>
            <div className={style.banner} ref={bannerRef}></div>
            {isLoaded && (
                <GoogleMap
                    id={id}
                    mapContainerStyle={{
                        width: '100%',
                        height: height,
                        minWidth: width,
                    }}
                    // Clear hover when mouse is outside polygon
                    onMouseMove={() => {
                        lastHover = null
                        if (bannerRef.current) {
                            bannerRef.current.innerHTML = ''
                        }
                    }}
                    options={mapOptionsStatic}
                    onLoad={(mapInstance) => setMap(mapInstance)}
                />
            )}
        </div>
    )
}

export default MunicipalitiesMap

/** Utility function to get heat color from a percentage */
export function getHeatColor(percentage: number, darker = false): string {
    const ratio = percentage / 100
    const red = Math.round(255 * ratio)
    const green = Math.round(255 * (1 - ratio))
    const blue = 0
    if (darker) {
        // make the colors a bit darker
        return `rgb(${Math.round(red * 0.8)}, ${Math.round(green * 0.8)}, ${Math.round(
            blue * 0.8
        )})`
    }
    return `rgb(${red}, ${green}, ${blue})`
}

/** The props for your MunicipalitiesMap component */
interface MunicipalitiesMapProps {
    height?: number | string
    /**
     * Object keyed by municipality_id (e.g. 336, 665, etc.).
     * Each value is a municipality object; you can refine the value type to your data's shape.
     */
    municipalities: Record<number, Municipality>
    /** Which country code's data to load.
     *  Note: This snippet uses uppercase "NL", "BE", "DE", "ES" in the dictionaries below.
     *  If you need it to be lowercase, adjust accordingly.
     */
    countryCode: 'NL' | 'BE' | 'DE' | 'ES' | string
    id?: string
    width?: number | string
    onClickMunicipality?: (municipality: Municipality) => void
    /**
     * A hover callback returning an object with a `banner` string.
     * If the municipality is hovered, that banner is rendered.
     */
    onHoverMunicipality?: (municipality: Municipality) => { banner: string }
    showAudienceSize?: boolean
    refreshTrigger?: any
    /**
     * E.g. {<municipality_id> : {fillColor:'red'}}
     * doesn't need all municipalities
     */
    municipalityStyles?: {
        [key: number]: MunicipalityStyle
        fallBack?: MunicipalityStyle
    }
    /** E.g. { lat: number, lng: number } */
    center?: { lat: number; lng: number }
    zoom?: number
    className?: string
}

// --- TYPES ---
/**
 * Interface for a single municipality.
 */
export interface Municipality {
    municipality_id: number
    name: string
    population: number
    latitude: number
    longitude: number
    country_code: string
    regionName: string
    region_id: number

    [key: string]: any
}

/** Styles that can be applied to a polygon for a given municipality */
export interface MunicipalityStyle {
    fillColor?: string
    fillOpacity?: number
    strokeColor?: string
    strokeOpacity?: number
    strokeWeight?: number
}
