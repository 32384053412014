import { Col, Row } from '@advanza/grid'
import { Divider } from '@advanza/ui'
import Card from 'components/Card'
import ServiceRequestStatsCalendarChart from 'components/serviceInfo/ServiceRequestStatsCalendarChart'
import ServiceStatsExportButton from 'components/serviceInfo/ServiceStatsExportButton'
import { useAppSelector } from 'hooks/hooks'
import { useRegionsList } from 'hooks/regionHooks'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import ServicesSelect from '../services/ServicesSelect'
import Page from '../ui/Page'
import ServiceCompetitionStats from './ServiceCompetitionStats'
import ServiceMatchStats from './ServiceMatchStats'
import ServicePricesActive from './ServicePricesActive'
import ServiceRequestStats from './ServiceRequestStats'
import ServiceRequestStatsAll from './ServiceRequestStatsAll'

const ServiceInfoPage = ({ match }) => {
    const [redirect, setRedirect] = useState(false)
    const id = match?.params?.id
    const serviceId = parseInt(id, 10)
    const { services } = useAppSelector((state) => state.services).entities
    const hasFetchedServiceListing = Object.keys(services).length > 0
    const regionsList = useRegionsList('all')?.entities?.regions ?? {}
    const country = useSelector((state) => state.countries.countries[0])

    const mandatoryRegions = Object.values(regionsList).filter((region) => !region.is_hidden)

    const visibleRegions = (() => {
        if (!serviceId) {
            return []
        }

        const service = Object.values(services).find((s) => s.service_id === serviceId)

        if (!service) {
            return []
        }

        const visibleRegions = service.services_visible_regions
            .filter((region) => region.is_visible)
            .map((region) => region.region_id)

        return Object.values(regionsList).filter((region) =>
            visibleRegions.includes(region.region_id)
        )
    })()

    const actualRegions = [...mandatoryRegions, ...visibleRegions]

    const onServiceChange = (services) => {
        setRedirect(`/sales/site-info/${services[0]}`)
        setTimeout(() => setRedirect(false), 0) // Reset redirect state
    }

    if (redirect) {
        return <Redirect to={redirect} push />
    }

    return (
        <Page title="Service Info">
            <Divider l />
            <Row>
                <Col x>
                    <ServicesSelect
                        value={serviceId?.toString() || ''}
                        onChangeValue={onServiceChange}
                        multiple={false}
                        emptyOption="-"
                        placeholder="select a service"
                    />
                </Col>
                <Col x>
                    <Divider />
                    <ServicePricesActive serviceId={serviceId} />
                    <Divider m />
                </Col>
                <Col x>{serviceId ? <ServiceStatsExportButton serviceId={serviceId} /> : null}</Col>
            </Row>

            {serviceId ? (
                <Row>
                    <Divider l />
                    <Col x>
                        <h2>
                            Active regions ({Object.keys(actualRegions).length}/
                            {Object.keys(regionsList).length})
                        </h2>
                        <Divider m />
                        <div>
                            {country === 'NL' || country === 'BE' ? (
                                <span>All of the regions</span>
                            ) : (
                                <div>
                                    {Object.values(actualRegions).map((region, index, arr) => (
                                        <span key={region.id}>
                                            {region.name}
                                            {index < arr.length - 1 ? ', ' : ''}
                                        </span>
                                    ))}
                                </div>
                            )}
                        </div>
                        <Divider m />
                    </Col>
                </Row>
            ) : null}

            {serviceId && hasFetchedServiceListing ? (
                <div>
                    <Row>
                        <Col x>
                            <Card>
                                <ServiceCompetitionStats serviceId={serviceId} />
                            </Card>
                            <Divider l />
                        </Col>
                        <Col x>
                            <Card>
                                <ServiceRequestStats serviceId={serviceId} />
                            </Card>
                        </Col>
                    </Row>
                    <Divider l />
                    <ServiceRequestStatsCalendarChart serviceId={serviceId} />
                    <Card useToggle header="Match stats" icon="check">
                        <Divider />
                        <ServiceMatchStats serviceId={serviceId} />
                        <Divider />
                    </Card>
                </div>
            ) : null}

            {!serviceId ? (
                <div>
                    <Divider l />
                    <ServiceMatchStats serviceId={0} />
                    <Divider l />
                    <ServiceRequestStatsAll serviceId={0} />
                </div>
            ) : null}
            <Divider xl />
        </Page>
    )
}

export default ServiceInfoPage
