import { de, es, nl, nlBE } from 'date-fns/locale'
import { objectKeys } from './object'

export type CountryCode = 'NL' | 'BE' | 'DE' | 'ES'
export type CountryCodeUpperLower = CountryCode | Lowercase<CountryCode>

export function formatNumber(number: bigint | number) {
    return new Intl.NumberFormat(getLocale(), {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(number)
}

export const formatPhoneDigitsOnly = (phone: string) => (phone || '').replace(/\D+/g, '')

/**
 * format with dash if price has 2 zeros: 12,00 => 12,-
 * no currency (psychological better for conversion)
 */
export function formatPriceWithoutCurrency(number: bigint | number) {
    return formatNumber(number).replace(/0{2}$/, '-')
}

export function formatPriceWithCurrency(number: number) {
    return `${number < 0 ? '-' : ''}€${formatPriceWithoutCurrency(Math.abs(number))}`
}

export function getLocale() {
    // locale format: <languageCode>-<countryCode>
    if (window.location.hostname.indexOf('trustlocal.be') !== -1) {
        return 'nl-BE'
    } else if (window.location.hostname.indexOf('trustlocal.de') !== -1) {
        return 'de-DE'
    } else if (window.location.hostname.indexOf('trustlocal.es') !== -1) {
        return 'es-ES'
    } else {
        return 'nl'
    }
}

export function getCountryCode() {
    const locale = getLocale()
    if (locale.length > 2) {
        return locale.split('-')[1] as 'BE' | 'DE' | 'ES'
    }
    return locale.toUpperCase() as 'NL'
}

export function getCompanyBaseUrl() {
    if (typeof window === 'undefined') return ''

    const { protocol, hostname } = window.location

    if (hostname.startsWith('localhost') || hostname.startsWith('l.')) {
        return `${protocol}//${hostname}:3000` // next app runs on port 3000 locally
    }

    return `https://${hostname}`
}

export const staticUrls: Record<string, string> = {
    NL: 'https://static.trustoo.nl',
    BE: 'https://static.trustlocal.be',
    DE: 'https://static.trustlocal.de',
}

export function getDateFnsLocale() {
    return {
        BE: nlBE,
        DE: de,
        NL: nl,
        ES: es,
    }[getCountryCode()]
}

// https://en.wikipedia.org/wiki/Regional_Indicator_Symbol
// https://mathiasbynens.be/notes/javascript-encoding#surrogate-formulae
export const getFlagEmoji = (countryCode: string): string => {
    const transCharCode = (charCode: number) => {
        const codePoint = 0x1f1e6 + charCode - 'A'.charCodeAt(0)
        return [
            Math.floor((codePoint - 0x10000) / 0x400) + 0xd800,
            ((codePoint - 0x10000) % 0x400) + 0xdc00,
        ]
    }

    countryCode = countryCode.toUpperCase()
    return String.fromCharCode(
        ...transCharCode(countryCode.charCodeAt(0)),
        ...transCharCode(countryCode.charCodeAt(1))
    )
}

export const validateVat = (val: string, paymentInfo: { country_code?: CountryCode }) =>
    !val ||
    Boolean(
        val.match(
            countrySettings[
                (paymentInfo.country_code?.toUpperCase() || getCountryCode()) as CountryCode
            ].vatRegex
        )
    )

export const countrySettings = {
    NL: {
        streetNumberExtra: false,
        wideStreetNumberExtra: false,
        useVatNumber: false,
        vatNumberInput: false,
        vatRegex: /^NL[0-9]{9}B[0-9]{2}$/,
        urlActivationAppointment:
            'https://trustoo.trustlocal.com/meetings/rr/activatiegesprek-trustoo',
    },
    BE: {
        streetNumberExtra: false,
        wideStreetNumberExtra: false,
        useVatNumber: true,
        vatNumberInput: false,
        vatRegex: /^BE[0-9]{10}$/,
        urlActivationAppointment:
            'https://trustoo.trustlocal.com/meetings/rr/activatiegesprek-trustlocal',
    },
    DE: {
        streetNumberExtra: false,
        wideStreetNumberExtra: false,
        useVatNumber: false,
        vatNumberInput: false,
        vatRegex: /^DE[0-9]{5,12}$/,
        urlActivationAppointment:
            'https://trustoo.trustlocal.com/meetings/rr/aktivierungsgesprach-trustlocal',
    },
    ES: {
        streetNumberExtra: true,
        wideStreetNumberExtra: true,
        useVatNumber: true,
        vatNumberInput: true,
        vatRegex: /^ES[0-9A-Z]{9}$/,
        urlActivationAppointment:
            'https://trustoo.trustlocal.com/meetings/rr/conversacion-de-activacion-trustoo',
    },
}

export const countryCodes = objectKeys(countrySettings)
