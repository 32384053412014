import { InputField } from '@advanza/input'
import { Button, Divider, LinearLoader, PreIcon } from '@advanza/ui'
import {
    fetchAllMunicipalities,
    fetchSubscription,
    getLeadEstimation,
    replaceSubscriptionMunicipalities,
    replaceSubscriptionOptions,
    saveSubscriptionGeoids,
    saveSubscriptionOptions,
} from 'actions/subscriptions'
import InputSlider from 'components/leadEstimator/InputSlider'
import LeadEstimatorServiceOptions from 'components/leadEstimator/LeadEstimatorServiceOptions'
import PercentageCircle from 'components/leadEstimator/PercentageCircle'
import StatListCircles from 'components/leadEstimator/StatListCircles'
import ServicesSelect from 'components/services/ServicesSelect'
import ServiceAreaListUltimate from 'components/subscriptions/ServiceAreaListUltimate'
import ServiceAreaMapUltimate from 'components/subscriptions/ServiceAreaMapUltimate'
import Page from 'components/ui/Page'
import SearchLocationInput from 'components/ui/SearchLocationInput'
import { useUrlQuery } from 'hooks/miscHooks'
import { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import style from './LeadEstimatorPage.module.css'

function formatLarge(nr) {
    return (
        <span>
            {nr && nr > 1000000 ? (nr / 1000000).toFixed(2) : (nr / 1000).toFixed(2)}
            {nr > 1000000 ? 'm' : 'k'}
        </span>
    )
}

let previous = ''

function useLeadEstimator(query) {
    const dispatch = useDispatch()
    const history = useHistory()
    const [isLoaded, setIsLoaded] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [positionBucket, setPositionBucket] = useState(0) // consider estimating the initial position bucket
    const positionBuckets = [
        { name: '1 - 4', value: 0, factor: 80 },
        { name: '4 - 10', value: 1, factor: 65 },
        { name: '> 10', value: 2, factor: 20 },
    ]
    const { estimations, entities, municipalities } = useSelector((state) => state.subscriptions)
    const { subscriptions } = entities
    const subId = query.subscriptionId
        ? `lead_estimator_${query.subscriptionId || 0}`
        : `lead_estimator_${query.serviceId}`
    const subscription = subscriptions && subscriptions[subId]
    const estimation = estimations[subId] || {}

    const total = (estimation && estimation.total) || 1
    let sharePopulation = 0
    let sharePopulationMunicipalities = 0
    let totalPop = 0

    municipalities.entities &&
        Object.keys(municipalities.entities).forEach((key) => {
            totalPop += municipalities.entities[key].population
        })
    subscription &&
        municipalities.entities &&
        subscription.subscriptions_municipalities.forEach((key) => {
            sharePopulationMunicipalities += estimation.municipalities[key.toString()] || 0
            sharePopulation +=
                (municipalities.entities[key] && municipalities.entities[key].population) || 0
        })
    const selectionOptionsEst = {}
    let shareFromSelectionOptions = total
    subscription &&
        estimation &&
        subscription.subscriptions_options &&
        subscription.subscriptions_options.forEach((key) => {
            const { question_id } = entities.options[key] || {}

            if (
                entities.questions[question_id] &&
                entities.questions[question_id].question_rules.length === 0
            ) {
                selectionOptionsEst[question_id] = selectionOptionsEst[question_id] || 0
                selectionOptionsEst[question_id] += estimation.options[key.toString()] || 0
            }
        })
    Object.keys(selectionOptionsEst).forEach((key) => {
        if (selectionOptionsEst[key] > 0) {
            shareFromSelectionOptions =
                shareFromSelectionOptions * (selectionOptionsEst[key] / total)
        }
    })

    const transferToSubscription = () => {
        if (!query.subscriptionId) {
            return Promise.resolve()
        } else {
            const subscriptionId = parseInt(query.subscriptionId)
            dispatch(fetchSubscription(subscriptionId)).then(() => {
                dispatch(
                    replaceSubscriptionMunicipalities(
                        subscriptionId,
                        subscription.subscriptions_municipalities
                    )
                )
                dispatch(
                    replaceSubscriptionOptions(subscriptionId, subscription.subscriptions_options)
                )
                dispatch(saveSubscriptionGeoids(subscriptionId))
                dispatch(saveSubscriptionOptions(subscriptionId))
                history.push(`/service/providers/subscription/${subscriptionId}/`)
            })
        }
    }

    useEffect(() => {
        if (query.dontFetch !== 'true') {
            setIsLoading(true)
            Promise.all([
                dispatch(getLeadEstimation({ ...query, subKey: subId })).then((response) => {
                    if (response.service) {
                        dispatch(fetchAllMunicipalities(response.service.country_code))
                    }
                }),
            ]).finally(() => {
                setIsLoaded(true)
                setIsLoading(false)
            })
            setIsLoaded(true)
        }
    }, [query])

    const calculatedEstimation = estimation && {
        populationShare: (sharePopulation / totalPop) * 100,
        municipalityShare: (sharePopulationMunicipalities / total) * 100,
        optionsShare: (shareFromSelectionOptions / total) * 100,
        positionBucketShare: positionBuckets[positionBucket].factor,
    }

    const sumShare = [
        total > 100 ? calculatedEstimation.municipalityShare : calculatedEstimation.populationShare,
        calculatedEstimation.optionsShare,
        calculatedEstimation.positionBucketShare,
    ].reduce((a, b) => (a * b) / 100, 100)

    calculatedEstimation.sum = sumShare
    calculatedEstimation.nrRequestsPerWeek = estimation.weekAvg * (sumShare / 100)

    return {
        isLoaded,
        totalPop,
        isLoading,
        sharePopulationMunicipalities,
        subscription,
        setPositionBucket,
        positionBuckets,
        positionBucket,
        transferToSubscription,
        shareFromSelectionOptions,
        selectionOptionsEst,
        calculatedEstimation,
        sharePopulation,
        estimation,
        subId,
    }
}

const LeadEstimatorPage = ({}) => {
    const { query, onSetQuery } = useUrlQuery()
    const leadEstimation = useLeadEstimator(query)
    useEffect(() => {
        previous = query.previous
    }, [])
    const {
        isLoaded,
        subId,
        estimation,
        positionBuckets,
        setPositionBucket,
        positionBucket,
        subscription,
        transferToSubscription,
        calculatedEstimation = {},
        isLoading,
    } = leadEstimation

    const backUrl =
        previous ||
        (query.subscriptionId &&
            `/service/providers/provider/${subscription && subscription.service_provider_id}/`)
    return (
        <Page title="Lead estimator">
            <div className={[style.grid, isLoading ? style.loading : ''].join(' ')}>
                <div className={style.header}>
                    <h1>
                        {backUrl && (
                            <Link to={backUrl}>
                                <PreIcon icon="arrow_back_ios" primColor>
                                    <Button name="text">Terug</Button>
                                </PreIcon>
                            </Link>
                        )}{' '}
                        Lead estimator
                    </h1>
                    {query.subscriptionId && (
                        <Button name="text" onClick={transferToSubscription}>
                            Opslaan in filter
                        </Button>
                    )}
                </div>

                {isLoading && <LinearLoader fixed />}
                {isLoaded && subscription && (
                    <Fragment>
                        <div className={style.results}>
                            <div className={style.weekEstimation}>
                                <PercentageCircle
                                    percentage={calculatedEstimation.sum}
                                    customText={calculatedEstimation.nrRequestsPerWeek.toFixed(1)}
                                />
                                <h4>Geschat aantal leads per week</h4>
                            </div>
                            <small>(n={estimation.total})</small>
                            <StatListCircles
                                className={style.summary}
                                stats={[
                                    {
                                        name: 'Geselecteerde filteropties',
                                        useHeat: true,
                                        percentage: calculatedEstimation.optionsShare,
                                    },

                                    {
                                        name: 'Op basis van werkgebied',
                                        useHeat: true,
                                        percentage: calculatedEstimation.municipalityShare,
                                    },

                                    {
                                        name: 'Bereik in aantal inwoners',
                                        useHeat: true,
                                        percentage: calculatedEstimation.populationShare,
                                    },

                                    {
                                        name: 'Positie in top 10',
                                        useHeat: true,
                                        percentage: calculatedEstimation.positionBucketShare,
                                    },
                                ]}
                            />
                            <Divider m />
                            <b>Gemiddelde positie in de top 10</b>
                            <Divider />
                            <InputSlider
                                onChange={(e) => setPositionBucket(e.target.value - 1)}
                                options={positionBuckets.map((bucket) => {
                                    return { name: bucket.name }
                                })}
                                value={positionBucket + 1}
                            />
                        </div>
                        <div className={style.list}>
                            <ServiceAreaMapUltimate
                                isLeadEstimator
                                estimation={estimation}
                                subscriptionId={subId}
                            />
                            <Divider l />
                            <ServiceAreaListUltimate isLeadEstimator subscriptionId={subId} />
                        </div>
                    </Fragment>
                )}
                <div className={style.options}>
                    <div className={style.controls}>
                        <ServicesSelect
                            value={parseInt(query.serviceId)}
                            onChangeValue={(value) =>
                                onSetQuery({ serviceId: value, subscriptionId: '' })
                            }
                            multiple={false}
                        />
                        <SearchLocationInput
                            value={query.cityName}
                            disabled={query.subscriptionId}
                            countryCode={subscription && subscription.country_code}
                            placeholder="City"
                            onChange={(e) =>
                                onSetQuery({ cityName: e.target.value, subscriptionId: '' })
                            }
                        />
                        <InputField
                            value={query.weeksAgo}
                            onChange={(e) =>
                                onSetQuery({ weeksAgo: e.target.value, dontFetch: 'true' })
                            }
                            onBlur={() => onSetQuery({ dontFetch: 0 })}
                            onKeyDown={(e) => e.key === 'Enter' && onSetQuery({ dontFetch: 0 })}
                            type="number"
                            placeholder="Weeks ago"
                        />
                    </div>
                    <Divider l />
                    <Divider name="border" />
                    <Divider l />
                    {subscription && (
                        <LeadEstimatorServiceOptions
                            subscription={subscription}
                            estimation={estimation}
                        />
                    )}
                </div>
            </div>
        </Page>
    )
}

export default LeadEstimatorPage
