import React, { Fragment, useState } from 'react'
import AutoRow from 'components/misc/AutoRow'
import { Button, Divider, Icon } from '@advanza/ui'
import Modal from '@advanza/modal'
import Card from 'components/Card'
import RegionsSelect from 'components/regions/RegionsSelect'

const actionBtn = ({ name, params, action = 'providers/schedule-keywords-task' }) => {
    return (
        <a target="_blank" href={`/api/office/${action}/${params.join('/')}`} rel="noreferrer">
            <Button>{name}</Button>
        </a>
    )
}

const modalBtn = ({ name, params, action = 'providers/schedule-keywords-task' }) => {
    const [openModal, setOpenModal] = useState(false)

    return (
        <Fragment>
            <Button onClick={() => setOpenModal(action)}>
                {name} <Icon name="forum" />
            </Button>
            <Modal close={() => setOpenModal(false)} maxWidth={600} open={openModal === action}>
                <div>
                    <h3>{name}</h3>
                    <Divider />
                    <AutoRow>{actionBtn({ name, params, action })}</AutoRow>
                    <Divider />
                </div>
            </Modal>
        </Fragment>
    )
}

const setNewImportUntouchedToEnabledModal = ({ serviceId }) => {
    const [openModal, setOpenModal] = useState(false)
    const [selectRegions, setSelectRegions] = useState([])

    return (
        <Fragment>
            <Button onClick={() => setOpenModal('set-new-import-untouched-to-enabled')}>
                Set new import untouched to enabled <Icon name="forum" />
            </Button>
            <Modal
                close={() => setOpenModal(false)}
                maxWidth={600}
                open={openModal === 'set-new-import-untouched-to-enabled'}>
                <div>
                    <h3>Sets all new_import_untouched profiles to enabled</h3>
                    <Divider />
                    Regions:
                    <RegionsSelect onChangeValue={setSelectRegions} value={selectRegions} />
                    <Divider />
                    <AutoRow>
                        {modalBtn({
                            name: 'Sets all new_import_untouched profiles to enabled',
                            params: [serviceId, selectRegions],
                            action: 'services/set-new-import-untouched-to-enabled',
                        })}
                    </AutoRow>
                    <Divider />
                </div>
            </Modal>
        </Fragment>
    )
}

const enablePausedSubscriptionsBtn = ({ serviceId }) => {
    const [openModal, setOpenModal] = useState(false)

    return (
        <Fragment>
            <Button onClick={() => setOpenModal('enable-paused-subscriptions')}>
                Enable paused subscriptions <Icon name="forum" />
            </Button>
            <Modal
                close={() => setOpenModal(false)}
                maxWidth={600}
                open={openModal === 'enable-paused-subscriptions'}>
                <div>
                    <h3>Enable paused subscriptions for this service?</h3>
                    <Divider />
                    <AutoRow>
                        {actionBtn({
                            name: 'Enable paused subscriptions',
                            params: [serviceId],
                            action: 'providers/enable-paused-subscriptions',
                        })}
                        {actionBtn({
                            name: 'Enable paused subscriptions (include signed)',
                            params: [serviceId, 1],
                            action: 'providers/enable-paused-subscriptions',
                        })}
                    </AutoRow>
                    <Divider />
                </div>
            </Modal>
        </Fragment>
    )
}

const ServiceActions = ({ serviceId }) => {
    return (
        <div>
            <AutoRow>
                {enablePausedSubscriptionsBtn({ serviceId })}
                {modalBtn({
                    name: 'Export providers with keywords / options',
                    params: [serviceId],
                    action: 'services/export-provider-keywords-options',
                })}
            </AutoRow>

            <Divider m />
            <AutoRow>{setNewImportUntouchedToEnabledModal({ serviceId })}</AutoRow>

            <Divider m />
            <Card header="Ghostdekking" useToggle icon="assignment">
                <Divider m />
                <AutoRow>
                    {modalBtn({
                        name: 'Set dekking labels for ghosts (1/2)',
                        params: [serviceId],
                        action: 'services/ghost-dekking',
                    })}
                    {modalBtn({
                        name: 'Enable providers with dekking labels (2/2)',
                        params: [serviceId],
                        action: 'services/enable-ghost-dekking',
                    })}
                </AutoRow>
            </Card>
        </div>
    )
}

export default ServiceActions
