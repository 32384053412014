import { _changeFilter, _fetchItems } from 'actions/lists'
import analyticsSchema from 'schemes/analyticsSchema'

export const ANALYTICS_CHANGE_FILTER = 'ANALYTICS_CHANGE_FILTER'
export const ANALYTICS_RECEIVE_LIST = 'ANALYTICS_RECEIVE_LIST'
export const ANALYTICS_REQUEST = 'ANALYTICS_REQUEST'

export function fetchList(filterId) {
    return _fetchItems(filterId, {
        url: 'office/providers/get-list-profile-performance-logs',
        schema: analyticsSchema,
        requestActionName: ANALYTICS_REQUEST,
        receiveActionName: ANALYTICS_RECEIVE_LIST,
        reducerKey: 'analytics',
    })
}

export function changeAnalyticsFilter(filterId, options) {
    return _changeFilter(filterId, options, {
        reducerKey: 'analytics',
        fetchFunc: fetchList,
        actionType: ANALYTICS_CHANGE_FILTER,
    })
}
