import { MunicipalitiesMap } from '@advanza/municipalityMap'
import AutoRow from 'components/misc/AutoRow'
import { useSubscriptionArea } from 'hooks/providerHooks'
import React from 'react'
import ReactDOMServer from 'react-dom/server'
import style from './ServiceAreaListUtlimate.module.css'

const ServiceAreaMapUltimate = ({ subscriptionId, isLeadEstimator, height = 400, ...rest }) => {
    const {
        municipalities,
        subscriptionMunicipalities,
        toggleMunicipality,
        subscription,
        service,
        postalCode,
        saveMunicipalities,
        mapCenter,
    } = useSubscriptionArea(subscriptionId, isLeadEstimator)

    const municipalityStyles = {}
    subscriptionMunicipalities?.forEach((municipalityId) => {
        municipalityStyles[municipalityId] = { fillOpacity: 0.7 }
    })
    const audienceSize = subscriptionMunicipalities?.reduce(
        (acc, municipalityId) => acc + municipalities[municipalityId]?.population,
        0
    )

    const renderBanner = (municipality) => {
        const isChecked = subscriptionMunicipalities?.includes(municipality.municipality_id)
        return ReactDOMServer.renderToStaticMarkup(
            <AutoRow>
                <div className={isChecked ? style.isChecked : ''} x>
                    {municipality.name}
                </div>
                <div>
                    <i className="material-symbols-outlined">people</i>{' '}
                    {(municipality.population / 1000).toFixed(2)}k
                </div>
                {service.highMunicipalityIds && (
                    <div>
                        <i className="material-symbols-outlined">sell</i>{' '}
                        {service.highMunicipalityIds?.includes(municipality.municipality_id)
                            ? 'High rate'
                            : 'Low rate'}
                    </div>
                )}
            </AutoRow>
        )
    }

    return (
        <div>
            <MunicipalitiesMap
                height={500}
                width={600}
                municipalities={municipalities}
                municipalityStyles={municipalityStyles}
                onClickMunicipality={(municipality) => {
                    toggleMunicipality(municipality.municipality_id)
                    saveMunicipalities()
                }}
                onHoverMunicipality={(municipality) => {
                    return {
                        banner: renderBanner(municipality),
                    }
                }}
                center={{
                    lat: mapCenter?.latitude || postalCode?.latitude || 0,
                    lng: mapCenter?.longitude || postalCode?.longitude || 0,
                }}
                countryCode={subscription.country_code.toUpperCase()}
                refreshTrigger={subscriptionMunicipalities}
            />
            <div>
                {audienceSize && audienceSize > 1000000
                    ? (audienceSize / 1000000).toFixed(2)
                    : (audienceSize / 1000).toFixed(2)}
                {audienceSize > 1000000 ? 'm' : 'k'}
            </div>
        </div>
    )
}

export default ServiceAreaMapUltimate
