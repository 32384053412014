import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useRegionsList } from 'hooks/regionHooks'
import Checkbox from 'components/input/Checkbox'

const RegionsSelect = ({ onChangeValue, value }) => {
    const { countries } = useSelector((state) => state.countries)
    const { currentPage, entities, changeFilter } = useRegionsList('all')
    useEffect(() => {
        changeFilter({ countries })
    }, [countries])
    const regions = []
    currentPage.forEach((id) => {
        regions.push(entities.regions[id])
    })
    return (
        <div>
            <Checkbox
                options={regions.map((e) => {
                    return { value: e.region_id, name: e.name }
                })}
                onChangeValue={onChangeValue}
                value={value}
            />
        </div>
    )
}

export default RegionsSelect
