import { getHeatColor, MunicipalitiesMap } from '@advanza/municipalityMap'
import { getRangeFromObject } from 'components/misc/misc'
import { useMunicipalities } from 'hooks/municipalityHooks'
import React from 'react'
import { useSelector } from 'react-redux'
import ServiceStatsContainer from '../../containers/ServiceStatsContainer'

const ServiceRequestsStatsGeoGraph = ({ getStatsAndOptions }) => {
    const { stats, counts = {} } = getStatsAndOptions('requestStats')
    const country = useSelector((state) => state.countries.countries[0])
    const { municipalityCounts } = counts
    const { municipalities } = useMunicipalities(country)
    const { range, min } = getRangeFromObject(municipalityCounts)
    const municipalityStyles = {}
    for (const key in municipalityCounts) {
        municipalityStyles[key] = {
            fillColor: getHeatColor((municipalityCounts[key] / (range + min)) * 100),
            fillOpacity: 0.5,
        }
    }
    const getHoverContent = (municipality) => {
        const municipalityId = municipality.municipality_id
        if (!municipalityCounts[municipalityId]) {
            return `<div>${municipality.name}</div>`
        }
        return `
        <div>${municipality.name}</div>
        ${municipalityCounts[municipalityId]} (${(
            (municipalityCounts[municipalityId] / stats.nrReq) *
            100
        ).toFixed(2)}%)
        `
    }

    return (
        <div>
            <MunicipalitiesMap
                municipalities={municipalities}
                countryCode={country}
                height={country === 'NL' ? 600 : 375}
                width={country === 'NL' ? 500 : 600}
                key="requests"
                id="requests"
                refreshTrigger={municipalityCounts}
                municipalityStyles={municipalityStyles}
                onHoverMunicipality={(municipality) => {
                    return {
                        banner: getHoverContent(municipality),
                    }
                }}
            />
        </div>
    )
}

export default ServiceStatsContainer(ServiceRequestsStatsGeoGraph)
