/**
 * We created a dataset for every country that contains the geojson with all municipalities
 * @see https://console.cloud.google.com/google/maps-apis/datasets...
 */
export const dataSets: Record<string, string> = {
    NL: 'c4e1fba7-8d37-4de5-8121-3af67ce19a8d',
    BE: '38ee2964-d72a-4cf7-8f9c-27a1d9e0cc8b',
    DE: 'b5a411dd-e461-4b5d-baf1-5eaeae9e9bfd',
    ES: '293b639f-bdf2-4b56-b4bb-82bbb035246c',
}

export const mapIds: Record<string, string> = {
    NL: '17eb8856c24b6823',
    BE: 'a42e4a90517ed521',
    DE: 'd84a559181d103ea',
    ES: '99f5327cfa461d0d',
}

export const GOOGLE_MAPS_API_KEY = 'AIzaSyCLJ_4vpnPQ0cppRvhYJlSvJ7O0_-sMhso'

// The municipality_id of the municipality in the center of the country
export const countryCenters: Record<string, number> = {
    NL: 336,
    BE: 665,
    DE: 1194,
    ES: 1569, // madrid municipality_id
}

export const defaultZoom: Record<string, number> = {
    NL: 7,
    BE: 7,
    DE: 6,
    ES: 6,
}

export const mapOptionsStatic: google.maps.MapOptions = {
    // @ts-ignore
    renderingType: 'VECTOR',
    disableDefaultUI: true,
    zoomControl: true,
    backgroundColor: '#b3cafe',
    clickableIcons: true,
    disableDoubleClickZoom: true,
    gestureHandling: 'cooperative',
}

export const polygonStyles = {
    inactive: {
        strokeColor: '#5A8BFC',
        strokeOpacity: 0.4,
        strokeWeight: 1,
        fillColor: '#5A8BFC',
        fillOpacity: 0.04, // they must have some filling to enable the mouse events
    },
    hover: {
        strokeColor: '#5A8BFC',
        strokeOpacity: 0.3,
        strokeWeight: 1,
        fillColor: '#5A8BFC',
        fillOpacity: 0.3,
    },
}
